import styled from "styled-components"
import { COLORS, IPAD_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../styles"
import SectionTitle from "../special-event/sectionTitle.style"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: ${COLORS.BEIGE};
  `,
  TeamTitle: styled(SectionTitle)`
    margin-top: 3rem;
    margin-bottom: 3rem;
  `,
  FirstMembersContainer: styled.div`
    width: 100%;
    padding-right: 10rem;
    padding-left: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2.5rem;

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      display: flex;
      flex-direction: column;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  `,
}
