import React from "react"
import Style from "./boardMember.style"

export interface BoardMemberType {
  id: string
  title: string
  name: string
  companies: string[] | null
  image: {
    fluid: any
    title: string
  }
}

interface Props {
  member: BoardMemberType
}

const BoardMember = ({ member }: Props) => (
  <Style.Container key={member.id}>
    {member.image && (
      <Style.ImageWrapper>
        <Style.ImageSizer />
        {member.image && (
          <Style.Image fluid={member.image.fluid} alt={member.image.title} />
        )}
      </Style.ImageWrapper>
    )}
    <Style.Name hasImage={!!member.image}>{member.name}</Style.Name>
    <Style.Position>{member.title}</Style.Position>
    {member.companies && (
      <Style.Companies>
        {member.companies.map(company => (
          <div key={company}>{company}</div>
        ))}
      </Style.Companies>
    )}
  </Style.Container>
)

export default BoardMember
