import Img from "gatsby-image"
import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../styles"

export const HERO_HEIGHT = `50vh`
export const MOBILE_HERO_HEIGHT = `40vh`

export default {
  Container: styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;
    align-items: center;
  `,

  Title: styled.h1`
    position: absolute;
    background-color: ${COLORS.HOLLY_GREEN};
    color: ${COLORS.BLUE_CHALK_VIOLET};
    cursor: default;
    font-family: ${FONTS.FIFTH_AVE_FONT_FAMILY};
    font-size: 28px;
    width: 70px;
    height: fit-content;
    padding: 18px 25px;
    text-orientation: upright;
    text-transform: uppercase;
    line-height: 1.18;
    letter-spacing: 2.8px;
    text-align: center;
    z-index: 1;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 30px;
      line-height: 1.17;
      letter-spacing: 3px;
    }
  `,
  Image: styled(Img)`
    position: absolute;
    height: 400px;
    width: 100%;
  `,
}
