import styled from "styled-components"
import { FONTS, MOBILE_MAX_WIDTH } from "../../styles"
import {
  HERO_HEIGHT,
  MOBILE_HERO_HEIGHT,
} from "../hero-title/heroTitleSection.style"
import { HEADER_HEIGHT } from "../header/header.style"

//TODO: redo page at new design
export default {
  MainText: styled.div`
    align-items: center;
    background-color: #e3edf2;
    color: black;
    display: flex;
    flex-direction: column;
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-size: 1rem;
    justify-content: center;
    line-height: 1.8rem;
    min-height: calc(100vh - ${HERO_HEIGHT} - ${HEADER_HEIGHT});
    padding: 2vh 20%;
    text-align: center;
    width: 100%;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: 1.4rem;
      min-height: calc(100vh - ${MOBILE_HERO_HEIGHT} - ${HEADER_HEIGHT});
    }
  `,
}
