import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/common/seo"
import HeroTitleSection from "../components/hero-title/heroTitleSection"
import Style from "../components/about/about.style"
import Team from "../components/about/team"
import { TeamMember } from "../components/about/member"
import { BoardMemberType } from "../components/about/boardMember"
import BoardMembers from "../components/about/boardMembers"

interface Props {
  data: {
    contentfulAboutPage: {
      title: string
      description?: string
      longMainText: {
        longMainText: string
        childMarkdownRemark: {
          html: string
        }
      }
      image: {
        title: string
        fluid: any
      }
      teamTitle: string
      boardMembersTitle: string
      boardMembersRightImage: {
        title: string
        fluid: any
      }
      boardMemberLeftImage: {
        title: string
        fluid: any
      }
    }
    allContentfulTeamMember: {
      edges: {
        node: TeamMember
      }[]
    }
    allContentfulBoardMember: {
      edges: {
        node: BoardMemberType
      }[]
    }
  }
}

const AboutPage = ({ data }: Props) => {
  const {
    title,
    image,
    longMainText: {
      childMarkdownRemark: { html },
    },
    teamTitle,
    description,
    boardMembersTitle,
    boardMemberLeftImage,
    boardMembersRightImage,
  } = data.contentfulAboutPage
  const teamMembers = data.allContentfulTeamMember.edges.map(edge => edge.node)
  const boardMembers = data.allContentfulBoardMember.edges.map(
    edge => edge.node
  )
  return (
    <Layout>
      <SEO title={title} description={description} />
      <HeroTitleSection name={title} image={image} />
      <Style.MainText
        dangerouslySetInnerHTML={{ __html: html }}
      ></Style.MainText>
      <Team teamTitle={teamTitle} teamMembers={teamMembers} />
      <BoardMembers
        title={boardMembersTitle}
        members={boardMembers}
        leftImage={boardMemberLeftImage}
        rightImage={boardMembersRightImage}
      />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    contentfulAboutPage {
      title
      description
      longMainText {
        longMainText
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
        title
      }
      teamTitle
      boardMembersTitle
      boardMembersRightImage {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      boardMemberLeftImage {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulTeamMember(sort: { fields: order, order: ASC }) {
      edges {
        node {
          email
          id
          name
          position
          image {
            fluid(quality: 70) {
              ...GatsbyContentfulFluid
            }
            title
          }
          description {
            description
          }
        }
      }
    }
    allContentfulBoardMember(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          title
          companies
          image {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`
