import React from "react"
import Style from "./member.style"

export interface TeamMember {
  email?: string
  id: string
  name: string
  position: string
  image: {
    fluid: any
    title: string
  }
  description?: {
    description: string
  }
}

interface Props {
  member: TeamMember
  className?: string
  firstMember?: boolean
}

const Member = ({
  member: { image, email, name, position },
  className,
  firstMember,
}: Props) => (
  <Style.Container className={className}>
    <Style.ImageWrapper>
      <Style.ImageSizer firstMember={Boolean(firstMember)} />
      {image && <Style.Image fluid={image.fluid} alt={image.title} />}
    </Style.ImageWrapper>
    <Style.Name>{name}</Style.Name>
    <Style.Position>{position}</Style.Position>
    {firstMember && email && (
      <Style.Email to={`mailto: ${email}`}>Contact Me</Style.Email>
    )}
  </Style.Container>
)

export default Member
