import Img from "gatsby-image"
import styled, { css } from "styled-components"
import { MOBILE_MAX_WIDTH } from "../../styles"
import { CTA } from "../common/cta"
import { H5, S1 } from "../common/text"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow: hidden;
    word-break: break-word;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  ImageSizer: styled.div<{ firstMember: boolean }>`
    // Define the ratio of the image, the padding is in % of the width of the Wrapper
    ${props =>
      props.firstMember
        ? css`
            padding-top: 80%;

            @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
              padding-top: 75%;
            }
          `
        : css`
            padding-top: 100%;
          `}
  `,
  Image: styled(Img)`
    // The Image takes the place of the Sizer and keep its ratio
    // !important is needed to override Gatsby Img inline style (position: relative)
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `,
  Name: styled(H5)`
    margin-top: 20px;
    margin-bottom: 5px;
  `,
  Position: styled(S1)``,
  Email: styled(CTA)``,
}
