import React from "react"
import Style from "./boardMembers.style"
import BoardMember, { BoardMemberType } from "./boardMember"

interface Props {
  title: string
  members: BoardMemberType[]
  leftImage: {
    title: string
    fluid: any
  }
  rightImage: {
    title: string
    fluid: any
  }
}

const BoardMembers = ({ title, members }: Props) => {
  const otherMembers = [...members]
  const firstMembers = otherMembers.splice(0, 5)

  const orderedMembers = [
    ...otherMembers.sort((a, b) => a.name.localeCompare(b.name)),
  ]

  return (
    <Style.Container>
      <Style.Title>{title}</Style.Title>
      <Style.FirstMembersContainer>
        {firstMembers.map(member => (
          <BoardMember key={member.id} member={member} />
        ))}
      </Style.FirstMembersContainer>
      <Style.MembersContainer>
        {orderedMembers.map(member => (
          <BoardMember key={member.id} member={member} />
        ))}
      </Style.MembersContainer>
    </Style.Container>
  )
}
export default BoardMembers
