import { useEffect, useRef } from "react"
import { MOBILE_MAX_WIDTH } from "../../styles"

export interface GenericEdge {
  node: {
    id: string
    title: string
    content: {
      content: string
    }
    image: {
      fluid: any
      title: string
    }
    externalLink: string
  }
}

// To create an infinite loop for UI rendering in case we have less than 3 items
export const CreateInfiniteLoopIllusion = (someArray: any[]): any[] => {
  switch (someArray.length) {
    case 0:
      return []
    case 2:
    case 3:
      if (
        typeof window !== "undefined" &&
        window.innerWidth > MOBILE_MAX_WIDTH
      ) {
        return someArray.concat(someArray, someArray)
      }
      return someArray
    default:
      return someArray
  }
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const urlHashFromText = (text: string) =>
  text
    .split(" ")
    .map(word => word.toLowerCase())
    .join("-")
