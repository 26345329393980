import styled, { css } from "styled-components"
import { COLORS, FONTS, IPAD_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../styles"

const sectionTitleStyle = css`
  font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
  font-size: ${FONTS.DESKTOP_SUBTITLE_FONT_SIZE};
  font-weight: ${FONTS.SUBTITLE_FONT_WEIGHT};
  color: ${COLORS.BOTTLE_GREEN};
  line-height: 1.58rem;
  letter-spacing: 0.056rem;
  text-transform: uppercase;

  @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
    font-size: ${FONTS.IPAD_SUBTITLE_FONT_SIZE};
    line-height: 1.1rem;
  }

  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: ${FONTS.MOBILE_SUBTITLE_FONT_SIZE};
    line-height: 0.83rem;
  }
`

export const SectionTitleLink = styled.a`
  ${sectionTitleStyle}
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default styled.div`
  ${sectionTitleStyle}
`
