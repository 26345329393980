import Img from "gatsby-image"
import styled, { css } from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import { H5, S1, T1 } from "../common/text"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 25%;
    margin-top: 4rem;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-basis: 50%;
    }
  `,
  ImageWrapper: styled.div`
    width: 80%;
    position: relative;
  `,
  // Define the ratio of the image, the padding is in % of the width of the Wrapper
  ImageSizer: styled.div`
    padding-top: 100%;
  `,
  // The Image takes the place of the Sizer and keep its ratio
  // !important is needed to override Gatsby Img inline style (position: relative)
  Image: styled(Img)`
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `,
  Name: styled(H5)<{ hasImage: boolean }>`
    text-align: center;
    ${({ hasImage }) =>
      hasImage &&
      css`
        margin-top: 1rem;
      `}
  `,
  Position: styled(S1)`
    text-align: center;
    margin-top: 1rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 0.5rem;
    }
  `,
  //TODO: Remove when new designs
  Companies: styled(T1).attrs({ $textColor: COLORS.CHARCOAL })`
    margin-top: 0.6rem;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 0.5rem;
    }
  `,
}
