import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { COLORS, IPAD_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../styles"
import SectionTitle from "../special-event/sectionTitle.style"

const memberContainerCommonStyle = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
`

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: ${COLORS.BEIGE};
    padding-right: 10rem;
    padding-left: 10rem;

    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  `,
  Title: styled(SectionTitle)`
    margin-top: 3rem;
  `,
  ImagesContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
    }
  `,
  ImageWrapper: styled.div`
    flex-basis: 45%;
    position: relative;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;

      &:last-child {
        margin-top: 4rem;
      }
    }
  `,
  ImageSizer: styled.div`
    // Define the ratio of the image, the padding is in % of the width of the Wrapper

    padding-top: 60%;
  `,
  Image: styled(Img)`
    // The Image takes the place of the Sizer and keep its ratio
    // !important is needed to override Gatsby Img inline style (position: relative)
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `,
  FirstMembersContainer: styled.div`
    ${memberContainerCommonStyle}
    justify-content: space-evenly;
  `,
  MembersContainer: styled.div`
    ${memberContainerCommonStyle}
    justify-content: center;
    padding-bottom: 3rem;
  `,
}
