import React from "react"
import Style from "./heroTitleSection.style"

interface Props {
  name: string
  image: {
    title: string
    fluid: any
  }
}

const HeroTitleSection = ({ name, image }: Props) => {
  return (
    <Style.Container>
      <Style.Title>{name}</Style.Title>
      {image && (
        <Style.Image
          imgStyle={{ objectPosition: "50% 10%" }}
          fluid={image.fluid}
          alt={image.title}
        />
      )}
    </Style.Container>
  )
}
export default HeroTitleSection
