import React from "react"
import Style from "./team.style"
import Member, { TeamMember } from "./member"
import { urlHashFromText } from "../common/app.utils"

interface Props {
  teamTitle: string
  teamMembers: TeamMember[]
}

const Team = ({ teamTitle, teamMembers }: Props) => {
  const teamTitleId = urlHashFromText(teamTitle)
  const threeFirstMembers = [...teamMembers].splice(0, 3)

  return (
    <Style.Container>
      <Style.TeamTitle id={teamTitleId}>{teamTitle}</Style.TeamTitle>
      <Style.FirstMembersContainer>
        {threeFirstMembers.map(member => (
          <Member key={member.id} member={member} firstMember />
        ))}
      </Style.FirstMembersContainer>
    </Style.Container>
  )
}

export default Team
